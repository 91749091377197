import programPaymentTypes, { programPaymentTypesFullNamesMap } from '../../../../enums/programPaymentTypes';
import reimbursementTypes, { reimbursementTypesFullNamesMap } from '../../../../enums/reimbursementTypes';
import moment from 'moment';
import { SLASHED_US_DATE_FORMAT } from '../../../../constants/formats';

const groupMembershipsInfoResources = {
    labelProgramSettings: 'Program Settings',
    labelPartnerSettings: 'Partner Settings',
    labelFacilitySettings: 'Facility Settings',
    labelMemberSettings: 'Member Settings',
    labelProgramFee: 'Program Fee',
    labelProgramPaymentType: 'Program Payment Type',
    labelNumberOfMemberships: 'Number Of Memberships',
    labelReimbursementType: 'Reimbursement Billing Type',
    labelFeesBillingType: 'Fees Billing Type',
    labelDuesForOneMemberPerMonthPaidToFacility: 'Dues Paid For 1 Member To Facility',
    labelNotes: 'Notes',
    labelAdminFee: 'Admin Fee',
    labelDuesAmount: 'Dues Amount',
    labelReimbursementAmount: 'Reimbursement Amount',
    labelNumberOfVisitsToReimburse: 'Number Of Visits to be Reimbursed',
    fieldProgramFee: 'monthlyInstallment',
    fieldProgramPaymentType: 'programPaymentTypeID',
    fieldNumberOfMemberships: 'numberOfMemberships',
    fieldReimbursementType: 'reimbursementTypeName',
    fieldFeesBillingType: 'facilityFeesBillingTypeName',
    fieldDuesForOneMemberPerMonthPaidToFacility: 'duesForOneMemberPerMonthPaidToFacility',
    fieldAdminFee: 'adminFee',
    fieldDuesAmount: 'memberDuesAmount',
    fieldReimbursementAmount: 'memberReimbursementAmount',
    fieldNumberOfVisitsToReimburse: 'numberOfVisitsToReimburse',
    fieldMemberReimbursementType: 'memberReimbursementTypeID',
    placeholderProgramPaymentType: 'Select Program Payment Type',
    fieldLaunchDate: 'launchDate',
    labelLaunchDate: 'Launch Date',
    fieldPaymentForActivatedMembersOnly: 'paymentForActivatedMembersOnly',
    labelPaymentForActivatedMembersOnly: 'Payment for activated members only',
    programPaymentTypeOptions: [
        { value: programPaymentTypes.partnerPaid, label: programPaymentTypesFullNamesMap[programPaymentTypes.partnerPaid] },
        { value: programPaymentTypes.memberPaid, label: programPaymentTypesFullNamesMap[programPaymentTypes.memberPaid] },
        { value: programPaymentTypes.partnerMemberPaid, label: programPaymentTypesFullNamesMap[programPaymentTypes.partnerMemberPaid] }],
    reimbursementTypeOptions: [
        { value: reimbursementTypes.toMember, label: reimbursementTypesFullNamesMap[reimbursementTypes.toFacility] }, // the label in the form should say "Direct To Bank Account" but the corresponding value to be stored is "Direct To Member"
        { value: reimbursementTypes.byPaycheck, label: reimbursementTypesFullNamesMap[reimbursementTypes.byPaycheck] },
        { value: reimbursementTypes.noReimbursements, label: reimbursementTypesFullNamesMap[reimbursementTypes.noReimbursements] },
    ],
};

export function GetAddingConfirmationMessage(programPaymentType) {
    return `Are you sure you want to create a ${groupMembershipsInfoResources.programPaymentTypeOptions.find(ppto => ppto.value === programPaymentType).label} program?`;
};

export function GetLaunchDateMinValue() {
    return moment().format(SLASHED_US_DATE_FORMAT);
};

export function GetLaunchDateMaxValue() {
    return moment().add(6, 'M')
        .format(SLASHED_US_DATE_FORMAT);
};

export default groupMembershipsInfoResources;
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import Table from '../../../components/InfoBox/Table';
import ActionCell from '../../../components/InfoBox/ActionCell';
import Spinner from '../../../components/Spinner/Spinner';
import { getPartnersList } from './partnersListActions';
import { connect } from 'react-redux';
import Button from '../../../components/controls/Button';
import routes from '../../../routing/routes';
import PartnerResources from '../../Shared/Partners/partnerResources';
import selectors from './partnersListSelectors';
import InfoBox from '../../../components/InfoBox/InfoBox';
import InfoBoxRow from '../../../components/InfoBox/InfoBoxRow';

function PartnersList({ partners, isLoading, getPartnersList, history }) {
    useEffect(() => {
        getPartnersList();
    }, []);
    const administerHandler = (partnerId) => {
        history.push(
            generatePath(routes.partnerDashboard.path, {
                partnerId
            })
        );
    };
    const columns = React.useMemo(
        () => [
            {
                Header: PartnerResources.labelPartnerIdNumber,
                accessor: 'partnerIDNumber',
                minWidth: 160,
            },
            {
                Header: PartnerResources.labelName,
                accessor: 'name',
                minWidth: 195,
            },
            {
                Header: PartnerResources.labelPrograms,
                accessor: 'programsDescription',
                disableSortBy: true,
                minWidth: 185
            },
            {
                Header: PartnerResources.labelCity,
                accessor: 'city',
            },
            {
                Header: PartnerResources.labelState,
                accessor: 'state.abbr',
                minWidth: 100
            },
            {
                Header: PartnerResources.labelCountry,
                accessor: 'country',
                minWidth: 130
            },
            {
                Header: 'Actions',
                disableSortBy: true,
                accessor: 'id',
                Cell: ActionCell,
                getProps: () => ({
                    handler: administerHandler,
                    label: 'Administer'
                }),
                minWidth: 200
            }
        ],
        [PartnerResources]
    );
    return isLoading ?
        (
            <Spinner />
        )
        : (
            <>
                <h2 className="layout__title">Partners</h2>
                <InfoBox bordered>
                    <InfoBoxRow>
                        <Button
                            className="btn_new btn_new--white"
                            onClick={() => {
                                history.push(routes.partnersAdd.path);
                            }}
                        >
                            {PartnerResources.addPartnerButtomText}
                        </Button>
                    </InfoBoxRow>
                    <Table columns={columns} data={partners.toJS()} />
                    <br />
                </InfoBox>
            </>
        );
}
PartnersList.propTypes = {
    partners: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    getPartnersList: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
};

const mapDispatchToProps = {
    getPartnersList
};
const connectStateToProps = connect(selectors, mapDispatchToProps);

export default connectStateToProps(PartnersList);
